addEventListener('DOMContentLoaded', (_event) => {
  if (!document.getElementById('review-bid')) return;
  const priceFields = document.querySelectorAll('.price-field');
  const subTotalElement = document.getElementById('sub-total');
  const salesTaxElement = document.getElementById('sales-tax');
  const salesTaxRate = salesTaxElement.dataset.rate ? parseFloat(salesTaxElement.dataset.rate) : 0;
  console.log(salesTaxRate);
  const totalElement = document.getElementById('total');

  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  const updateTotals = () => {
    let subTotal = 0;  
    for (const priceField of priceFields) {
      subTotal += parseFloat(priceField.value);
    }
    const salesTax = subTotal * salesTaxRate;
    subTotalElement.innerHTML = formatCurrency(subTotal);
    salesTaxElement.innerHTML = formatCurrency(salesTax);
    totalElement.innerHTML = formatCurrency(subTotal + salesTax);
  }

  for (const priceField of priceFields) {
    priceField.addEventListener('input', updateTotals);
  }
});